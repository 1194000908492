<template>
  <div>
    <TrackList :style="trackListStyle" :tracks="trackGroups.group(currentLetter)" />
    <div id="letter-menu" ref="letterMenu">
      <LetterMenu
        :letters="trackGroups.letters"
        :initialLetter="currentLetter"
        @letter-selected="currentLetter = $event" />
    </div>
  </div>
</template>

<script>

import { TrackGroups } from '../tracks.js'
import TrackList from './TrackList.vue'
import LetterMenu from './LetterMenu.vue'

export default {
  name: 'TrackGroupsList',
  components: {
    TrackList,
    LetterMenu
  },
  props: ["tracks"],
  data () {
    return {
      currentLetter: "A",
      trackGroups: new TrackGroups(this.tracks),
      trackListStyle: {},
    }
  },
  mounted() {
    const height = this.$refs.letterMenu.clientHeight + "px";
    this.$set(this.trackListStyle, "margin-bottom", height);
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#letter-menu {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: #111111;
  border-top: 1px solid #000000;
  width: 100%;
}

</style>
