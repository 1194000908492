<template>
  <ul id="tracks">
    <li v-for="track of tracks" :key="key(track)">
      <TrackItem :track="track" />
    </li>
  </ul>
</template>

<script>

import TrackItem from './TrackItem.vue'

export default {
  name: 'TrackList',
  props: ["tracks"],
  components: {
    TrackItem,
  },
  methods: {
    key(track) {
      return track.id.toString() + track.starred.toString();
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#tracks {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

</style>
