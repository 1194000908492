export class StarredTracks {
  constructor() {
    this.load();
  }

  starred(id) {
    return this.items.has(id);
  }

  star(id) {
    if (!this.starred(id)) {
      this.items.add(id);
      this.save();
    }
  }

  unstar(id) {
    if (this.starred(id)) {
      this.items.delete(id);
      this.save();
    }
  }

  load() {
    try {
      this.items = new Set(JSON.parse(localStorage.starred));
    } catch (e) {
      if (e.name === "SyntaxError") {
        this.items = new Set();
      } else {
        throw(e);
      }
    }
  }

  save() {
    localStorage.starred = JSON.stringify(Array.from(this.items));
  }
}

export class Track {
  constructor(data, starredTracks) {
    this.data = data;
    this.starredTracks = starredTracks;
    this.starred = this.starredTracks.starred(this.data.id);
  }

  get id() { return this.data.id; }
  get title() { return this.data.title; }
  get artist() { return this.data.artist; }

  toggleStarred() {
    if (this.starred) {
      this.starredTracks.unstar(this.data.id);
    } else {
      this.starredTracks.star(this.data.id);
    }
    this.starred = this.starredTracks.starred(this.data.id);
  }

  titleLetter() {
    const letter = this.title.charAt(0).toUpperCase();
    if (letter >= 0 && letter <= 9) {
      return "#"
    }
    return letter;
  }

  artistLetter() {
    const letter = this.artist.charAt(0).toUpperCase();
    if (letter >= 0 && letter <= 9) {
      return "#"
    }
    return letter;
  }

  static compareArtists(a, b) {
    if (a.artist.toLowerCase() < b.artist.toLowerCase()) {
      return -1;
    }

    if (a.artist.toLowerCase() > b.artist.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  static compareTitles(a, b) {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  static compareByArtistAndTitle(a, b) {
    const res = Track.compareArtists(a, b);
    if (res === 0) {
      return Track.compareTitles(a, b);
    }
    return res;
  }

  static compareByTitleAndArtist(a, b) {
    const res = Track.compareTitles(a, b);
    if (res === 0) {
      return Track.compareArtists(a, b);
    }
    return res;
  }
}

function partition(xs, keyfunc) {
  const res = new Map();
  let cur = []
  let key = null;
  xs.forEach(x => {
    const val = keyfunc(x);
    if (key === null) {
      key = val;
    } else if (key !== val) {
      res.set(key, cur);
      cur = [];
      key = val;
    }
    cur.push(x);
  });
  res.set(key, cur);
  return res;
}

export class TrackGroups {
  constructor(tracks) {
    this.tracks = Array.from(tracks);
    this.tracks.sort(Track.compareByArtistAndTitle)
    this.groups = partition(this.tracks, x => x.artistLetter());
  }

  group(letter) {
    return this.groups.get(letter);
  }

  get letters() {
    return Array.from(this.groups.keys());
  }
}
