<template>
  <div id="app">
    <div v-if="welcome">
      <WelcomeScreen :to="welcome.to" @click="welcome = null" />
    </div>
    <div v-else>
      <div id="header">
        <div id="header-content">
          <h1>OK Karaoke</h1>
        </div>
      </div>
      <div id="navbar">
        <div id="navbar-content">
          <button
            v-for="tab in tabs"
            :key="tab"
            :disabled="currentTab === tab"
            @click="changeTab(tab)">
            {{ tab }}
          </button>
        </div>
      </div>

      <div id="content">
        <keep-alive>
          <SearchList v-if="currentTab === 'Search'" :tracks="tracks"/>
        </keep-alive>

        <keep-alive>
          <TrackGroupsList v-if="currentTab === 'A-Z'" :tracks="tracks"/>
        </keep-alive>

        <div v-if="currentTab === 'Starred'">
          <keep-alive>
            <TrackList v-if="starred.length"
                       :tracks="starred"
                       :key="JSON.stringify(starred)" />
          </keep-alive>
          <p id="empty" v-if="!starred.length">
            None available
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Track, StarredTracks } from './tracks.js'
import TrackList from './components/TrackList.vue'
import TrackGroupsList from './components/TrackGroupsList.vue'
import SearchList from './components/SearchList.vue'
import WelcomeScreen from './components/WelcomeScreen.vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    SearchList,
    TrackList,
    TrackGroupsList,
    WelcomeScreen,
  },

  data () {
    const tabs = ["Search", "A-Z", "Starred"];
    return {
      tracks: [],
      tabs: tabs,
      currentTab: tabs[0],
      welcome: null
    }
  },

  computed: {
    starred: function() {
      return this.tracks.filter(x => x.starred);
    },
  },

  methods: {
    changeTab(tab) {
      this.currentTab = tab;
      window.scrollTo(0,0);
    }
  },

  async created () {
    switch (window.location.search.substr(1)) {
      case "omg":
        this.welcome = {
          to: "Oscar"
        };
        break;
      case "wtf":
        this.welcome = {
          to: "Daniel"
        };
        break;
      default:
        this.welcome = null;
    }
    const starred = new StarredTracks();
    const response = await axios.get("/tracks.json")
    this.tracks = response.data.map(x => new Track(x, starred));
    this.tracks.sort(Track.compareByArtistAndTitle);
  },
}
</script>

<style>
body {
  margin: 0px;
  overflow-y: scroll;
  background-color: #282828;
  color: #ffffff;
}

h1 {
  margin: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Barlow, Open Sans, Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
}

#header {
  background-color: #111111;
}

#header-content {
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding-top: 10px;
}

#content {
  max-width: 800px;
  margin: 10px auto;
}

#navbar {
  top: 0px;
  position: sticky;
  position: -webkit-sticky;
  border-bottom: 1px solid black;
  width: 100%;
  z-index: 100;
  background-color: #111111;
}

#navbar-content {
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: center;
}

#navbar button {
  padding: 15px 15px;
  background-color: #111111;
  border: 0;
  font-weight: normal;
  color: #cccccc;
}


#navbar button:disabled {
  font-weight: bold;
  color: #ffffff;
}

#empty {
  margin-top: 30px;
  text-align: center;
}

*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
</style>
