<template>
  <div id="welcome-screen">
    <div id="welcome-screen-content">
      <p>Till {{ to }} från Eskil,</p>
      <img src="xmas-caption.png" />
      <img src="xmas-white-640.png" />
      <p><a href="/">Ta mig till paketet ...</a></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  props: ["to"],
}
</script>

<style>
#welcome-screen {
  background-color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#welcome-screen-content {
  max-width: 800px;
  margin: auto;
  text-align: center;
  padding-top: 10px;
  height: 100%;
}

#welcome-screen-content img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

#welcome-screen-content p {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  color: black;
  font-size: larger;
}
</style>
