<template>
  <div>
    <div id="search-box">
      <input type="text" placeholder="Type an artist or title ..." @input="search"/>
    </div>
    <TrackList :tracks="matching" />
  </div>
</template>

<script>

import TrackList from './TrackList.vue'

export default {
  name: 'SearchList',
  props: ["tracks"],
  components: {
    TrackList,
  },

  data () {
    return {
      matching: []
    }
  },

  methods: {
    search(e) {
      const query = e.target.value.toLowerCase();

      if (query.length >= 3) {
        this.matching = this.tracks.filter(x => x.artist.toLowerCase().includes(query) || x.title.toLowerCase().includes(query));
      } else {
        this.matching = [];
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#search-box {
  padding: 16px 10px;
}

input {
  padding: 5px;
  font-size: 24px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #2c3e50;
  border-radius: 5px;
}

</style>
