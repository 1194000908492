<template>
  <div>
    <ul>
      <li v-for="letter of letters" :key="letter" :class="{selected: currentLetter === letter}">
        <button @click="onClickLetter(letter)">{{ letter }}</button>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'LetterMenu',
  props: [
    "letters",
    "initialLetter",
  ],

  data () {
    return {
      currentLetter: this.initialLetter
    }
  },

  methods: {
    onClickLetter(letter) {
      this.currentLetter = letter;
      this.$emit("letter-selected", letter);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button {
  color: #42b983;
  background: none;
  border: 0;
  padding: 0px;
  outline: none;
}

ul {
  list-style-type: none;
  margin-left: 5px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

li {
  display: block;
  padding: 5px;
  flex: 0;
}

li.selected {
  background-color: #EEEEEE;
  border-radius: 10px;
}

</style>
