<template>
  <div class="track">
    <div v-if="focus" @click="focus = false" id="focus">
      <div class="focus-id">{{ track.id }}</div>
      <div class="focus-title">{{ track.title }}</div>
      <div class="focus-artist">{{ track.artist }}</div>
    </div>
    <div class="track-item">
      <div @click="focus = true">
        <div class="track-title">{{ track.title }}</div>
        <div class="track-artist">{{ track.artist }}</div>
      </div>
      <div class="track-starred"
        :class="{'track-starred-unselected': !track.starred}"
        @click="track.toggleStarred()">
          ⭐
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TrackItem',
  props: ["track"],
  data () {
    return {
      focus: false
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.track {
}

.track-item {
  display: flex;
  margin: 0 10px;
  padding: 10px 0px;
  justify-content: space-between;
}

.track-title {
  font-size: 100%;
  margin-bottom: 6px
}

.track-artist {
  font-size: 80%;
  color: #aaaaaa;
}

.track-starred {
  margin-top: 8px;
}

.track-starred-unselected {
  color: transparent;
  text-shadow: 0 0 0 grey;
}

#focus {
  z-index: 999;
  position: fixed;
  background-color: #90c0de;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(28, 123, 183);
}

.focus-id {
  margin-left: 15px;
  letter-spacing: 15px;
  font-size: 100px;
  font-weight: bold;
  padding-bottom: 25px;
}

.focus-title {
  font-size: 32px;
  padding-bottom: 10px;
}

.focus-artist {
  font-size: 20px;
}

</style>
